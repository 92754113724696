<template>
  <v-main :anchor-layout="layoutName" v-if="!isLogin">
    <v-container class="fill-height grey darken-3" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" lg="8" xl="6">
          <v-card
            v-if="layoutReady"
            class="elevation-0"
          >
            <v-card-text>
              <router-view></router-view>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import layoutMixins from '@/layout/layoutMixins'
export default {
  mixins: [layoutMixins],
  data: () => ({
    layoutName: 'unauth',
  }),
  methods: {
    redirect() {
      const path = this.$route.query.path
      if(path) {
        this.$router.replace({ path })
        return
      }
      this.$router.replace({ name: 'admin.entry' })
    },
  },
  watch: {
    isLogin: {
      immediate: true,
      handler() {
        if(this.isLogin) this.redirect()
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>